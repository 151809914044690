import React, { useState } from 'react';

import { Button, Typography } from '@material-ui/core';
import { HtmlTooltip } from '../components/About';

export const Contact = props => {
  const [showAdd, setShowAdd] = useState(false);
  const address = (
    <div className="btn btn-light">
      <Typography variant="body1" color="white">
        PO Box Number 224
      </Typography>
      <Typography variant="body1" color="white">
        485 Speedwell Avenue
      </Typography>
      <Typography variant="body1" color="white">
        Morris Plains, NJ, 07950
      </Typography>{' '}
    </div>
  );
  return (
    <div>
      <section className="content-section bg-primary text-white" id="contact">
        <div className="container text-center">
          <h2 className="mb-4">Get in touch with us.</h2>
          <Button
            variant="contained"
            color="default"
            onClick={() => props.showModal()}
            className="btn btn-xl btn-light mr-4"
          >
            <Typography variant="body1" color="primary">
              Contact us
            </Typography>
          </Button>

          <HtmlTooltip arrow title={address}>
            <Button
              variant="contained"
              color="primary"
              className="btn btn-xl btn-dark"
              onClick={() => setShowAdd(!showAdd)}
            >
              Write to us
            </Button>
          </HtmlTooltip>
          {showAdd && <div className="mobile-address">{address}</div>}
        </div>
      </section>

      <section className="map">
        <iframe
          title="map"
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3019.1186187169774!2d-74.48213538428513!3d40.82535813860906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3a720922eac5b%3A0x18fedbd7fa5d30fe!2s485%20Speedwell%20Ave%2C%20Morris%20Plains%2C%20NJ%2007950!5e0!3m2!1sen!2sus!4v1641066156240!5m2!1sen!2sus"
        ></iframe>
        <br />
      </section>
    </div>
  );
};
