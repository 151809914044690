import React, { useState } from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import Footer from '../components/Footer';
import Header from '../components/Header';
import { Contact } from '../components/Contact';
import { About } from '../components/About';
import { Portfolio } from '../components/Portfolio';
import ScrollToTop from '../components/ScrollToTop';
import { Morph } from '../components/Morph';

import SimpleModal from '../components/SimpleModal';
import { ContactForm } from '../components/ContactForm';

import logo from '../assets/images/Logo-transparent.svg';

const IndexPage = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Layout>
      <Header />
      <SimpleModal open={showModal} onClose={() => setShowModal(false)}>
        <ContactForm onCancel={() => setShowModal(false)} />
      </SimpleModal>

      <header className="masthead d-flex">
        <div className="container text-center my-auto">
          <Morph content="Trusted digital partner" />
          <div style={{ display: 'inline', flexDirection: 'column' }}>
            <p>
              <img style={{ height: '40vh' }} src={logo} alt="logo" />
            </p>
            {/* <h1 className="mb-1">{config.heading}</h1>
        <h3 className="mb-5">
          <em>{config.subHeading}</em>
        </h3> */}
            <Scroll type="id" element="about" offset={100}>
              <a className="btn btn-secondary btn-xl" href="#about">
                About
              </a>
            </Scroll>
          </div>
        </div>
        <div className="overlay"></div>
      </header>

      <About />

      <section
        className="content-section bg-primary text-white text-center"
        id="services"
      >
        <div className="container">
          <div className="content-section-heading">
            <h3 className="text-secondary mb-0">Services</h3>
            <h2 className="mb-5">Our experience and capabilities</h2>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
              <span className="service-icon rounded-circle mx-auto mb-3">
                <i className="icon-basket"></i>
              </span>
              <h4>
                <strong>E Commerce</strong>
              </h4>
              <p className="text-faded mb-0">
                CMS, payment gateway, cart, POS integration
              </p>
            </div>
            <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
              <span className="service-icon rounded-circle mx-auto mb-3">
                <i className="icon-heart"></i>
              </span>
              <h4>
                <strong>Health care</strong>
              </h4>
              <p className="text-faded mb-0">
                Telehealth, EHR Integration, Pharma manafacturing and process
                control.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 mb-5 mb-md-0">
              <span className="service-icon rounded-circle mx-auto mb-3">
                <i className="icon-printer"></i>
              </span>
              <h4>
                <strong>IOT / Consumer electronics</strong>
              </h4>
              <p className="text-faded mb-0">
                Raspberry pi, Arduino.
                {/* <i className="fas fa-heart"></i> */}
              </p>
            </div>
            <div className="col-lg-3 col-md-6">
              <span className="service-icon rounded-circle mx-auto mb-3">
                <i className="icon-mustache"></i>
              </span>
              <h4>
                <strong>Consulting</strong>
              </h4>
              <p className="text-faded mb-0">
                Strategy, planning and project management services.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="callout">
        <div className="container text-center">
          <h2 className="mx-auto mb-5">
            What do you want to
            <span>
              {' '}
              <em>build</em>{' '}
            </span>
            today?
          </h2>
          <a
            className="btn btn-primary btn-xl"
            href="https://github.com/anubhavsrivastava/gatsby-starter-stylish"
          >
            Download Now!
          </a>
        </div>
      </section> */}

      <Portfolio showModal={() => setShowModal(true)} />

      <Contact showModal={() => setShowModal(true)} />

      <ScrollToTop />
      <Footer />
    </Layout>
  );
};
export default IndexPage;
