import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Button, Typography } from '@material-ui/core';

export const ContactForm = props => {
  const [values, setValues] = useState({});
  const handleChange = e => {
    const updated = { ...values, [e.target.name]: e.target.value };
    setValues(updated);
  };
  return (
    <div>
      <form
        name="contact"
        method="post"
        action="https://getform.io/f/b8552a31-ff44-4ec7-87d8-0e97ef8a8625"
        encType="application/x-www-form-urlencoded"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        // onSubmit={this.handleSubmit}
      >
        <Typography variant="body1">
          Please fill out the contact information. We respond within 24 hours
        </Typography>
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot-field" onChange={handleChange} />
          </label>
        </div>

        <TextField
          style={{
            width: '100%',
          }}
          type="text"
          name="name"
          id="name"
          label="Name"
          onChange={handleChange}
        />

        <TextField
          style={{
            width: '100%',
          }}
          type="text"
          name="email"
          id="email"
          label="Email"
          onChange={handleChange}
        />

        <TextField
          style={{
            width: '100%',
          }}
          name="message"
          id="message"
          label="Message"
          onChange={handleChange}
          multiline
          rows={5}
        />

        <div
          style={{
            width: '100%',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!values.name || !values.email || !values.message}
          >
            Submit
          </Button>

          <Button
            onClick={() => props.onCancel()}
            style={{ float: 'right' }}
            color="secondary"
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};
