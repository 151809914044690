import React from 'react';

import Scroll from '../components/Scroll';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

export const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export const About = props => {
  const principles = [
    {
      heading: 'Automation',
      description: (
        <p>
          If it can be automated, do it. This <em>pay it forward</em> principle
          helps us avoid major headaches that can amount to huge maintenance
          costs later in the game.
        </p>
      ),
    },
    {
      heading: 'Student forever',
      description: (
        <p>
          We never stop learning. Just like technology is always in motion, we
          try to constantly educate ourselves to enhance and evolve our skills.
        </p>
      ),
    },
    {
      heading: 'Quality',
      description: (
        <p>
          We go to great extents to make a solution weigh heavy on the merits.
          We often do comparisons of various offerings on different parameters
          so that we can apply whatever works best for a certain problem.
        </p>
      ),
    },

    {
      heading: 'Empathy',
      description: (
        <p>
          We try to understand the pain points of the business so that we truly
          appreciate where the customer is coming from. There is not a{' '}
          <em>one size fits all</em> type of solution when it comes to the
          businesses.
        </p>
      ),
    },
  ];
  return (
    <div>
      <section className="content-section bg-light" id="about">
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <h2>
                RCR Labs was founded to help small and medium sized businesses
                establish a digital presence.
              </h2>
              <div style={{ textAlign: 'left' }}>
                <p className="lead">
                  We also offer development of additional capabilities in the
                  ever evolving digital landscape.
                </p>
                <p className="lead mb-5">
                  We have a varied range of experience in the software industry
                  and have seen various trends and technologies from the ground
                  up.
                </p>
                <span
                  style={{
                    marginBottom: '50px',
                    display: 'flex',
                    flexDirection: 'row',
                    verticalAlign: 'bottom',
                  }}
                >
                  <p className="lead">Our Founding principles:</p>
                  <div
                    style={{
                      textAlign: 'center',
                      verticalAlign: 'bottom',
                      // display: ['none', 'none', 'none', 'inline-block'],
                      // marginTop: '-60px',
                      marginTop: '-45px',
                      marginLeft: '10px',
                    }}
                  >
                    {principles.map(p => {
                      return (
                        <HtmlTooltip
                          key={principles.indexOf(p)}
                          title={
                            <React.Fragment>
                              <Typography>{p.heading}</Typography>
                              {p.description}
                            </React.Fragment>
                          }
                          arrow
                        >
                          <span className="principle">{p.heading[0]}</span>
                        </HtmlTooltip>
                      );
                    })}
                  </div>
                  <span className="lead">
                    {' '}
                    &nbsp; (pronounced{' '}
                    <em>
                      <b>ask</b>
                    </em>
                    )
                  </span>
                </span>
              </div>
              <Scroll type="id" element="services" offset={100}>
                <a className="btn btn-dark btn-xl" href="#services">
                  What We Offer
                </a>
              </Scroll>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

About.propTypes = {};
