import React from 'react';
import p1 from '../assets/images/portfolio-1.jpg';
import p2 from '../assets/images/portfolio-2.jpg';
import p4 from '../assets/images/portfolio-4.jpg';
import pos from '../assets/images/pos.jpg';
import p5 from '../assets/images/portfolio-5.png';
import { Button } from '@material-ui/core';

export const Portfolio = props => {
  return (
    <div>
      <section className="content-section" id="portfolio">
        <div className="container">
          <div className="content-section-heading text-center">
            <h3 className="text-secondary mb-0">Portfolio</h3>
            <h2 className="mb-5">Recent Projects</h2>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-6">
              <div className="portfolio-item">
                <span className="caption">
                  <span className="caption-content">
                    <div className="know-more">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.showModal()}
                      >
                        Learn More
                      </Button>
                    </div>
                    <h2>Event scheduling and signup</h2>
                    <p className="mb-0">
                      HIPAA compliant form design and data persistence with
                      ability to accept payments
                    </p>
                  </span>
                </span>
                <img className="img-fluid" src={p5} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="portfolio-item">
                <span className="caption">
                  <span className="caption-content">
                    <div className="know-more">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.showModal()}
                      >
                        Learn More
                      </Button>
                    </div>
                    <h2>Digitization of the process workflow</h2>
                    <p className="mb-0">
                      Software to help reduce the paperwork in a controlled
                      manufacturing process.
                    </p>
                  </span>
                </span>
                <img className="img-fluid" src={p1} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="portfolio-item">
                <span className="caption">
                  <span className="caption-content">
                    <div className="know-more">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.showModal()}
                      >
                        Learn More
                      </Button>
                    </div>
                    <h2>Website for business promotion</h2>
                    <p className="mb-0">
                      A website designed to publish the custom content like
                      ebooks, youtube videos, and signup for news letters.
                    </p>
                  </span>
                </span>
                <img className="img-fluid" src={p2} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="portfolio-item" href="/#">
                <span className="caption">
                  <span className="caption-content">
                    <div className="know-more">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.showModal()}
                      >
                        Learn More
                      </Button>
                    </div>
                    <h2>
                      Integration of Legacy POS with online ordering system
                    </h2>
                    <p className="mb-0">
                      An adapter to iterface a printer with polling protocol to
                      a cart that updates via webhooks.
                    </p>
                  </span>
                </span>
                <img className="img-fluid" src={pos} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="portfolio-item" href="/#">
                <span className="caption">
                  <span className="caption-content">
                    <div className="know-more">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.showModal()}
                      >
                        Learn More
                      </Button>
                    </div>
                    <h2>Tools for learning from home</h2>
                    <p className="mb-0">
                      An easy to use calendar based interface, which issues
                      spoken notificaitons for kids relying on Virtual classes.
                    </p>
                  </span>
                </span>
                <img className="img-fluid" src={p4} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
